/* requries:
jquery-3.2.1.min.js
slick.js

parallax1-TweenMax.min.js
parallax2-ScrollMagic.min.js
parallax3-animation.gsap.min.js
*/


$(document).ready(function(){

	function homepageHero() {
		if ( $('#homepage-hero').length ) {

		    // - To find this value divide the video's native width by the height eg 1920/1080 = 1.78
		    var aspectRatio = 1.78;

		    var video = $('#video-iframe'),
		    	videoHeight = $('#homepage-hero').outerHeight(),
		    	newWidth = videoHeight*aspectRatio,
		    	halfNewWidth = newWidth/2;

		    videoEmbedSizing(newWidth, halfNewWidth);

		    function videoEmbedSizing(newWidth, halfNewWidth) {
		    	video.css({"width": newWidth + "px","left":"50%","margin-left":"-" + halfNewWidth + "px"});
		    }
		    
		    $(window).on('resize', function() {

		    	videoHeight = video.outerHeight();
		    	newWidth = videoHeight*aspectRatio;
		    	halfNewWidth = newWidth/2;

		    	videoEmbedSizing(newWidth, halfNewWidth);

		    });

		}

	}
	
	
	var centerPad = ($('.about-slider').find('>div').outerWidth() / 2) + 'px',
		$aboutSlider = $('.about-slider'),
		$aboutSliderYearsItem = $('.about-slider-years li'),
		$aboutSliderMobileSelect = $('.about-slider-years-mobile select'),
		slickSettings = {
			centerMode: true,
		    centerPadding: centerPad,
		    slidesToShow: 1,
		    variableWidth: true,
		    prevArrow: '<div class="about-slider-prev"></div>',
		    nextArrow: '<div class="about-slider-next"></div>',
		    responsive: [
			    {
			    	breakpoint: 900,
			      	settings: {
			        	centerMode: false,
			        	variableWidth: false
			      }
			    },
			]
		};

	$aboutSlider
		.on('init', function(event, slick) {
			if ( $(window).width() > 900 ) {
				$('.about-slider-prev').css('left', ($(window).width() - $('.slick-current').width())/2 - 50);
				$('.about-slider-next').css('right', ($(window).width() - $('.slick-current').width())/2 - 50);
			}
		})
		.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			var slide = $(slick.$slides[nextSlide]),
				yearIndex = slide.data('slide-year-index');

			$aboutSliderYearsItem.eq(yearIndex).addClass('active').siblings().removeClass('active');
			$aboutSliderMobileSelect.prop('selectedIndex', yearIndex);

			// reset mobile drop down
			$('.select .option.selected').removeClass('selected');
			$('.select .option').eq(yearIndex).addClass('selected');
			$('.select-trigger span').empty().text( $('.select .option').eq(yearIndex).text() );

		})
		.slick(slickSettings);

	$(window).on('resize', function() {
		if ( $(window).width() > 900 ) {
			centerPad = ($('.about-slider').find('>div').outerWidth() / 2) + 'px';
			$('.about-slider-prev').css('left', ($(window).width() - $('.slick-current').width())/2 - 50);
			$('.about-slider-next').css('right', ($(window).width() - $('.slick-current').width())/2 - 50);
			$aboutSlider.slick("slickSetOption", "centerPadding", centerPad, false);
		}
	});

	function goToSlide(index) {
		$('.about-slider').slick('slickGoTo', parseInt(index));
	}

	$aboutSliderYearsItem.click(function(e) {
        e.preventDefault();
        var slideIndex = $(this).data('slide-index');
        goToSlide(slideIndex);

    });


    function dropDown () {
    	$('.dropdown-years-mobile .select-trigger').click (function (e) {
    		e.stopPropagation ()
    		var options = $(this).siblings('.options');
    		options.show ().css('width', $(this).width() + 'px');

    		// close the dropdown if the user clicks outside of it
    		$('.homepage-timeline').click (function (e) {
    			options.hide ();
    			$(this).unbind('click');
    		})
    	});

    	$('.dropdown-years-mobile .option:not(.selected)').click(function(e) {
    		// go to the correct slide
    		var selectValue = $(this).attr('data-value');
    		goToSlide(selectValue);
    		
    		$('.option.selected').removeClass('selected');
			$(this).addClass('selected');
			$(this).parents('.options').hide();
			$('.select-trigger span').empty().text( $(this).text() );

    	});

    	$('.dropdown-years-mobile .option').eq(0).addClass('selected');
    	
    }

    var mobileMenu = function() {
		$('.site-header-hamburger').click(function() {
			$('.main-navigation').toggleClass('open');
			$( this ).toggleClass('open');
		});

		$('.main-navigation').on("click", "a", function () {
			// only toggle these classes on mobile
			if ($('.header-share.desktop-only').css('display') === 'none') {
				$('.main-navigation').toggleClass('open');
				$('.site-header-hamburger').toggleClass('open');
			}
		});
  	}

	function toggleHelper () {
		$('.toggle-more').click (function (e) {
			$(this).hide();
		})
	}

	function setParallax (items, startSpeed, increase, increment) {
		var speed = startSpeed;

		items.each (function (index) {
			$(this).attr('data-rellax-speed', speed);
			$(this).addClass('initiated');

			if (increase === true) {
				speed = speed + increment;

			} else if (index%2 == 0) {
				speed = 1;
			} else {
				speed = 1.5;
			}			
		})
	}


	function scrollTop () {
		$('a.top-link').click (function (e) {
			e.preventDefault();
			$('html, body').animate({
		      scrollTop: 0
		    }, 500);
		})
	}


    function init() {
    	homepageHero();
    	mobileMenu();
    	dropDown();
    	toggleHelper();
    	setParallax($('.homepage-news .item.rellax'), .75, true, .5);
//    	setParallax($('.homepage-pilots .item.rellax'), 1.33, false, 0);
    	scrollTop();
    }

    init();

});